import { useQuery } from "@tanstack/react-query";
import { fetchStudentAttendanceService } from "../../services/users.services";
// import { FiUserCheck } from "react-icons/fi";
// import { FiUserX } from "react-icons/fi";
import { useEffect, useState } from "react"
import AttendanceModal from "./attendance-modal";


const StudentAttendance = ({ className }) => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [table, setTable] = useState([])
  const [showAttendance, setShowAttendance] = useState(false)
  const [attendanceType, setAttendanceType] = useState('offline')

  useEffect(() => {
    const conditions = [
      { category: 20, program_id: 67, batch_id: 94, status: "offline" },
      { category: 4, program_id: 43, batch_id: 111, status: "online" },
      { category: 4, program_id: 47, batch_id: 111, status: "online" },
      { category: 4, program_id: 42, batch_id: 111, status: "online" },
      { category: 4, program_id: 88, batch_id: 111, status: "online" },
      { category: 4, program_id: 61, batch_id: 111, status: "online" },
      { category: 4, program_id: 60, batch_id: 111, status: "online" },
      { category: 4, program_id: 102, batch_id: 111, status: "online" },
      { category: 22, program_id: 69, batch_id: 97, status: "online" },
      { category: 22, program_id: 69, batch_id: 107, status: "online" },
    ];

    const matchedCondition = conditions.find(condition =>
      localStorage.getItem("category") == condition.category &&
      localStorage.getItem("program_id") == condition.program_id &&
      localStorage.getItem("batch_id") == condition.batch_id
    );

    if (matchedCondition) {
      setShowAttendance(true);
      setAttendanceType(matchedCondition.status);
    }
  }, [])

  const { data } = useQuery({
    queryFn: () => fetchStudentAttendanceService({ category: localStorage.getItem("category"), attendanceType: attendanceType }),
    queryKey: ['get-attendance'],
    enabled: !!localStorage.getItem("category") && showAttendance
  })

  useEffect(() => {
    if (data?.attendance) {
      setTable(data?.attendance?.attendance_details)
    }
  }, [data])

  return (
    <>
      {showAttendance && (
        <>
          <div onClick={() => setIsModalOpen(true)} className={`w-full py-2 px-4 bg-white border font-circular rounded-lg cursor-pointer flex items-center gap-2 justify-between ${className}`}>
            <div className="flex items-center gap-3">
              <h1 className="font-semibold text-lg">Attendance: </h1>
              <h1 className="text-lg">{data?.attendance?.present_count}/{data?.attendance?.total_attendance}</h1>
            </div>
            <h4 className="text-universal text-xs font-semibold">View More</h4>
          </div>
          <AttendanceModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} table={table} />
        </>
      )}
    </>
  );
}

export default StudentAttendance;